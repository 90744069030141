.report-container {
    position: relative;
    width: 100% !important;
    height: calc(92vh) !important; /* Account for header (64px) + padding (48px) + margins */
    min-height: 400px;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

/* Base styles for the controls toolbar */
.report-controls {
    position: absolute;
    z-index: 100;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 12px;
    padding: 6px 8px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    transition: all 0.2s ease;
    opacity: 0.95;
    cursor: move;
    user-select: none;
    touch-action: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    will-change: transform;
    border: 1px solid rgba(235, 235, 235, 0.9);
}

.report-controls * {
    touch-action: none;
    pointer-events: auto;
}

.report-controls:hover {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
    border-color: rgba(255, 255, 255, 1);
}

.report-controls.dragging {
    opacity: 1;
    transform: scale(1.02);
    background-color: rgba(255, 255, 255, 0.98);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
    transition: none; /* Disable transitions during drag for smoother movement */
}

/* Position classes for the toolbar */
.report-controls.left-top {
    top: 16px;
    left: 16px;
}

.report-controls.right-top {
    top: 16px;
    right: 16px;
}

.report-controls.left-bottom {
    bottom: 16px;
    left: 16px;
}

.report-controls.right-bottom {
    bottom: 16px;
    right: 16px;
}

/* Drag handle */
.drag-handle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 32px;
    margin-right: 4px;
    cursor: grab;
    position: relative;
    opacity: 0.6;
    transition: opacity 0.2s ease;
}

.drag-handle::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    opacity: 0;
    transition: opacity 0.2s ease;
}

.report-controls:hover .drag-handle {
    opacity: 0.8;
}

.report-controls:hover .drag-handle::before {
    opacity: 1;
}

.drag-handle span {
    display: block;
    width: 4px;
    height: 4px;
    background-color: #555;
    border-radius: 50%;
    margin: 2px 0;
    position: relative;
    z-index: 1;
}

.report-controls.dragging .drag-handle {
    cursor: grabbing;
    opacity: 1;
}

.report-controls.dragging .drag-handle::before {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.08);
}

.report-refresh-icon {
    background: none;
    border: none;
    width: 32px;
    height: 32px;
    padding: 4px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    background-color: rgba(255, 255, 255, 0.7);
    position: relative;
    z-index: 2; /* Ensure button is above other elements */
    border: 1px solid rgba(235, 235, 235, 0.9);
}

.report-refresh-icon svg {
    width: 20px;
    height: 20px;
    fill: #555;
    transition: transform 0.3s ease;
}

.report-refresh-icon:hover {
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
    border-color: rgba(220, 220, 220, 1);
}

.report-refresh-icon:active {
    transform: scale(0.95);
}

.report-refresh-icon.refreshing svg {
    animation: spin 1.2s linear infinite;
}

.report-refresh-icon:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.toast-alert {
    position: fixed;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(211, 47, 47, 0.9);
    color: white;
    padding: 10px 16px;
    border-radius: 4px;
    font-size: 14px;
    max-width: 80%;
    text-align: center;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    z-index: 2000;
    animation: fadeInUp 0.3s, fadeOut 0.3s 4.7s;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate(-50%, 20px);
    }
    to {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Add responsive adjustments if needed */
@media (max-width: 768px) {
    .report-container {
        height: calc(100vh - 80px) !important;
    }
    
    .report-container iframe {
        transition: transform 0.2s ease-out;
        touch-action: pan-y pinch-zoom;
        -webkit-overflow-scrolling: touch;
        will-change: transform;
    }

    .report-controls {
        padding: 4px 6px;
        gap: 6px;
    }
    
    .report-controls.left-top,
    .report-controls.right-top {
        top: 12px;
    }
    
    .report-controls.left-top,
    .report-controls.left-bottom {
        left: 12px;
    }
    
    .report-controls.right-top,
    .report-controls.right-bottom {
        right: 12px;
    }
    
    .report-controls.left-bottom,
    .report-controls.right-bottom {
        bottom: 12px;
    }
    
    .drag-handle {
        width: 14px;
        height: 28px;
        margin-right: 2px;
    }
    
    .drag-handle span {
        width: 3px;
        height: 3px;
    }
    
    .report-refresh-icon {
        width: 28px;
        height: 28px;
        padding: 3px;
    }
    
    .report-refresh-icon svg {
        width: 18px;
        height: 18px;
    }
    
    .toast-alert {
        font-size: 13px;
        padding: 8px 12px;
        max-width: 90%;
    }
} 