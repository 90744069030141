@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* Layout */
  .settings-container {
    @apply space-y-4 md:space-y-6;
  }

  /* Header */
  .settings-header {
    @apply flex items-center gap-3 md:gap-4 mb-4 md:mb-6;
  }

  .settings-header-icon {
    @apply p-2 md:p-3 rounded-full;
  }

  .settings-title {
    @apply text-xl md:text-2xl font-semibold text-gray-900 dark:text-white;
  }

  .settings-subtitle {
    @apply text-sm md:text-base text-gray-500 dark:text-gray-400;
  }

  /* Card */
  .settings-card {
    @apply bg-white dark:bg-gray-800/50 backdrop-blur-sm rounded-xl 
           border border-gray-200/50 dark:border-white/10 
           p-4 md:p-6 space-y-4 md:space-y-6;
  }

  /* Form Elements */
  .settings-input {
    @apply w-full px-3 md:px-4 py-2 
           bg-white dark:bg-gray-700 
           border border-gray-300 dark:border-gray-600 
           rounded-lg text-gray-900 dark:text-white 
           focus:ring-2 focus:ring-orange-500 
           placeholder-gray-500 dark:placeholder-gray-400
           text-sm md:text-base;
  }

  .settings-select {
    @apply w-full px-3 md:px-4 py-2 
           bg-white dark:bg-gray-700 
           border border-gray-300 dark:border-gray-600 
           rounded-lg text-gray-900 dark:text-white 
           focus:ring-2 focus:ring-orange-500
           text-sm md:text-base;
  }

  /* Buttons */
  .settings-button {
    @apply flex items-center gap-2 px-3 md:px-4 py-2 
           bg-orange-500 hover:bg-orange-600 
           text-white rounded-lg transition-colors
           text-sm md:text-base;
  }

  /* Dividers */
  .settings-divider {
    @apply pt-4 md:pt-6 mt-4 md:mt-6 border-t border-gray-200 dark:border-gray-700 flex justify-end;
  }

  /* Toggle Switch - Optional if you want a consistent style */
  .settings-toggle {
    @apply w-10 md:w-11 h-5 md:h-6 
           bg-gray-200 dark:bg-gray-700 
           peer-focus:ring-4 peer-focus:ring-orange-500/20 
           rounded-full peer 
           peer-checked:after:translate-x-full 
           after:content-[''] after:absolute after:top-[2px] after:start-[2px] 
           after:bg-white after:border-gray-300 after:border 
           after:rounded-full after:h-4 md:after:h-5 after:w-4 md:after:w-5 after:transition-all 
           peer-checked:bg-orange-500;
  }

  /* Text styles for consistency */
  .settings-label {
    @apply block text-xs md:text-sm font-medium text-gray-700 dark:text-gray-300 mb-1;
  }

  .settings-text {
    @apply text-sm md:text-base text-gray-900 dark:text-white;
  }

  .settings-text-secondary {
    @apply text-xs md:text-sm text-gray-500 dark:text-gray-400;
  }
  
  /* Mobile specific styles */
  .settings-mobile-section {
    @apply mb-6;
  }
  
  .settings-mobile-section-title {
    @apply text-sm font-medium text-gray-500 mb-2;
  }
  
  .settings-mobile-card {
    @apply bg-white rounded-xl overflow-hidden shadow-sm border border-gray-200;
  }
  
  .settings-mobile-item {
    @apply flex items-center justify-between p-4 text-gray-700 border-b border-gray-200 last:border-b-0;
  }
  
  .settings-mobile-back {
    @apply flex items-center text-gray-600 py-3;
  }

  /* Toggle Item and Toggle Switch */
  .toggle-item {
    @apply flex justify-between items-center py-2;
  }

  .toggle {
    @apply relative inline-block w-12 h-6 rounded-full bg-gray-400 cursor-pointer transition-colors duration-200 ease-in-out;
  }

  .toggle.active {
    @apply bg-blue-500;
  }

  .toggle.disabled {
    @apply bg-gray-300 cursor-not-allowed opacity-50;
  }

  .toggle-slider {
    @apply absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform duration-200 ease-in-out;
  }

  .toggle.active .toggle-slider {
    @apply transform translate-x-6;
  }
} 