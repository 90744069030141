/* Stream Chat Custom Styling */

/* Channel List */
.str-chat-channel-list {
  height: 100% !important;
  background-color: #f9fafb !important;
}

/* Channel Preview */
.str-chat__channel-preview-messenger {
  padding: 0.75rem 1rem !important;
  margin: 0.25rem 0.5rem !important;
  border-radius: 0.5rem !important;
  transition: background-color 0.2s !important;
  font-size: 1rem !important;
}

.str-chat__channel-preview-messenger:hover {
  background-color: #f3f4f6 !important;
}

.str-chat__channel-preview-messenger--active {
  background-color: #fff7ed !important; /* orange-50 */
  border-left: 3px solid #f97316 !important; /* orange-500 */
}

.str-chat__channel-preview-messenger--active .str-chat__channel-preview-messenger-name {
  font-weight: 600 !important;
}

/* Empty State */
.str-chat__channel-list-empty {
  padding: 1rem !important;
  font-size: 0.875rem !important;
  color: #6b7280 !important;
}

.str-chat__empty-channel {
  background-color: #f9fafb !important;
  margin: 0 !important;
}

.str-chat__empty-channel-text {
  color: #6b7280 !important;
  font-size: 1rem !important;
  margin-top: 1rem !important;
}

/* Message Input */
.str-chat__input-flat {
  padding: 1rem !important;
  border-top: 1px solid #e5e7eb !important;
  background-color: #ffffff !important;
}

.str-chat__input-flat-wrapper {
  border-radius: 0.5rem !important;
  border: 1px solid #e5e7eb !important;
  padding: 0.5rem !important;
}

.str-chat__input-flat-wrapper:focus-within {
  border-color: #f97316 !important; /* orange-500 */
  box-shadow: 0 0 0 2px rgba(249, 115, 22, 0.2) !important; /* orange-500 with opacity */
}

.str-chat__textarea textarea {
  font-size: 1rem !important;
  padding: 0.375rem 0.75rem !important;
}

/* Send Button */
.str-chat__send-button {
  background-color: #f97316 !important; /* orange-500 */
  border-radius: 0.375rem !important;
  height: 2.25rem !important;
  width: 2.25rem !important;
  margin-right: 0.25rem !important;
}

.str-chat__send-button:hover {
  background-color: #ea580c !important; /* orange-600 */
}

/* Messages */
.str-chat__message-simple {
  padding: 0.75rem 1rem !important;
  font-size: 1rem !important;
  margin-bottom: 0.5rem !important;
}

.str-chat__message-simple__content {
  border-radius: 0.75rem !important;
  padding: 0.75rem 1rem !important;
}

.str-chat__message-simple--me .str-chat__message-simple__content {
  background-color: #f97316 !important; /* orange-500 */
}

.str-chat__message-simple--me .str-chat__message-text-inner {
  color: #ffffff !important;
}

.str-chat__message-simple__actions {
  background: none !important;
}

/* Message text */
.str-chat__message-text {
  font-size: 1rem !important;
  line-height: 1.5 !important;
}

/* Avatars */
.str-chat__avatar {
  width: 40px !important;
  height: 40px !important;
  font-size: 1rem !important;
}

/* Channel Header */
.str-chat__header-livestream {
  padding: 1rem !important;
  height: auto !important;
  min-height: 4rem !important;
  box-shadow: none !important;
  border-bottom: 1px solid #e5e7eb !important;
  background-color: #ffffff !important;
}

.str-chat__header-livestream-left {
  padding-left: 0 !important;
}

.str-chat__header-livestream-title {
  font-size: 1.125rem !important;
  font-weight: 600 !important;
}

.str-chat__header-livestream-subtitle {
  font-size: 0.875rem !important;
  margin-top: 0.125rem !important;
}

/* Message List */
.str-chat__list {
  padding: 1rem 0 !important;
  background-color: #ffffff !important;
}

.str-chat__virtual-list {
  height: 100% !important;
}

.str-chat__message-list-scroll {
  padding: 0 0.5rem !important;
}

/* Date Separators */
.str-chat__date-separator {
  margin: 1.5rem 0 !important;
}

.str-chat__date-separator-date {
  font-size: 0.875rem !important;
  color: #6b7280 !important;
  background: white !important;
  padding: 0 0.75rem !important;
}

/* Loading Indicator */
.str-chat__loading-indicator {
  color: #f97316 !important; /* orange-500 */
}

/* Search Input */
.str-chat__channel-search {
  padding: 0.75rem !important;
  border-bottom: none !important;
}

.str-chat__channel-search-input {
  border-radius: 0.375rem !important;
  border: 1px solid #e5e7eb !important;
  padding: 0.5rem 0.75rem !important;
  font-size: 0.875rem !important;
}

.str-chat__channel-search-input:focus {
  border-color: #f97316 !important; /* orange-500 */
  box-shadow: 0 0 0 2px rgba(249, 115, 22, 0.2) !important; /* orange-500 with opacity */
}

/* Thread */
.str-chat__thread {
  background-color: #f9fafb !important;
  border-left: 1px solid #e5e7eb !important;
}

.str-chat__thread-header {
  height: auto !important;
  min-height: 4rem !important;
  padding: 1rem !important;
  background-color: #f9fafb !important;
  border-bottom: 1px solid #e5e7eb !important;
}

.str-chat__thread-header-title {
  font-size: 1.125rem !important;
  font-weight: 600 !important;
}

/* Mobile Optimization */
@media (max-width: 768px) {
  .str-chat__input-flat {
    padding: 0.75rem !important;
  }
  
  .str-chat__header-livestream {
    min-height: 3.5rem !important;
  }

  .str-chat__message-simple {
    padding: 0.5rem 0.75rem !important;
  }
}

/* Add styles for chat status indicators */
.chat-status {
  display: inline-block;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  border-radius: 9999px;
  font-weight: 500;
}

.chat-status.connecting {
  background-color: #fef3c7;
  color: #92400e;
}

.chat-status.connected {
  background-color: #d1fae5;
  color: #065f46;
}

.chat-status.disconnected {
  background-color: #fee2e2;
  color: #b91c1c;
}

/* Lodgeic Custom Styling for Sendbird UI Kit */
.lodgeic-channel-list .sendbird-channel-list {
  background-color: #f9fafb;
  border-right: 1px solid #e5e7eb;
}

.lodgeic-channel-list .sendbird-channel-preview {
  border-radius: 0.5rem;
  margin: 0.25rem 0.5rem;
}

.lodgeic-channel-list .sendbird-channel-preview:hover {
  background-color: #f3f4f6;
}

.lodgeic-channel-list .sendbird-channel-preview--active {
  background-color: rgba(249, 115, 22, 0.1); /* orange-500 with opacity */
  border-left: 3px solid #f97316; /* orange-500 */
}

.lodgeic-channel-list .sendbird-channel-preview__content__upper__title {
  font-weight: 600;
}

.lodgeic-channel .sendbird-chat-header {
  background-color: #ffffff;
  border-bottom: 1px solid #e5e7eb;
  height: auto;
  min-height: 4rem;
}

.lodgeic-channel .sendbird-message-input {
  border-top: 1px solid #e5e7eb;
  padding: 0.75rem;
}

.lodgeic-channel .sendbird-message-input--textarea {
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
}

.lodgeic-channel .sendbird-message-input--textarea:focus-within {
  border-color: #f97316; /* orange-500 */
  box-shadow: 0 0 0 2px rgba(249, 115, 22, 0.2); /* orange-500 with opacity */
}

.lodgeic-channel .sendbird-message-input--send-button {
  background-color: #f97316; /* orange-500 */
}

.lodgeic-channel .sendbird-message-input--send-button:hover {
  background-color: #ea580c; /* orange-600 */
}

.lodgeic-channel .sendbird-message-content__middle__message-item-body {
  font-size: 1rem;
}

.lodgeic-channel .sendbird-user-message.outgoing .sendbird-user-message__text-balloon {
  background-color: #f97316; /* orange-500 */
  color: white;
}

.lodgeic-channel .sendbird-user-message.incoming .sendbird-user-message__text-balloon {
  background-color: #f3f4f6;
}

.lodgeic-channel .sendbird-message-search {
  border-bottom: 1px solid #e5e7eb;
}

.lodgeic-channel .sendbird-message-search__input {
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
}

.lodgeic-channel .sendbird-message-search__input:focus {
  border-color: #f97316; /* orange-500 */
  box-shadow: 0 0 0 2px rgba(249, 115, 22, 0.2); /* orange-500 with opacity */
}

/* Custom styles for Power BI filter share messages */
.sendbird-message-content .pbi-filter-share {
  width: 100%;
  margin: 8px 0;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid rgb(254, 215, 170);
  background-color: rgb(255, 247, 237);
}

.sendbird-message-content .pbi-filter-share-header {
  padding: 10px 12px;
  background-color: rgb(254, 215, 170);
  color: rgb(194, 65, 12);
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.sendbird-message-content .pbi-filter-share-header svg {
  margin-right: 8px;
}

.sendbird-message-content .pbi-filter-share-body {
  padding: 12px;
}

.sendbird-message-content .pbi-filter-share-button {
  display: block;
  width: 100%;
  padding: 8px 12px;
  background-color: rgb(249, 115, 22);
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  margin: 8px 0;
}

.sendbird-message-content .pbi-filter-share-button:hover {
  background-color: rgb(234, 88, 12);
}

.sendbird-message-content .pbi-filter-share-meta {
  font-size: 12px;
  color: rgb(107, 114, 128);
  margin-top: 8px;
} 